import React from "react"
import { graphql } from 'gatsby';
import Layout from "@components/kaigai-fx/layout"
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";
import FormsParts from "@components/kaigai-fx/forms-contactform7-fx";

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';

// コンポーネント呼び出し
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成

const FxContact = ({data}) => {
  const indexUrl = '/kaigai-fx/';
  const pageTitle = 'お問い合わせ'
  const searchList = SearchCategList().Main; // カテゴリ検索リスト
  const wordSearchContentPC = WordSearch().PC; // ワード検索PC
  const wordSearchContentMB = WordSearch().MB; // ワード検索MB
  
  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });

  // 関連記事取得(components/related-article.js)
  const getArchiveVisual = data.imagesettingData.edges.filter(item => item.node.slug === 'archive-visual')[0];
  const tagArray = [
    {name: "pickup", slug: "pickup"},
    {name: "おすすめ", slug: "recommend"}
  ]       
  const relatedList = RelatedContents([''], tagArray, '', getArchiveVisual); 
             
  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB                    
      
  return (
    <> 
      <Layout>
        {seoTag}
        <div className="md:max-w-6xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          <div className="policy_cont max-w-4xl pb-5 px-5 mb-10 md:pb-0 md:mb-0 w-full">
            <div className="text-left mb-3 mx-auto flex items-center">
              <p><img alt="" src={icon_t_SVG1}/></p>
              <h1 className="ml-5 font-semibold tracking-wide text-[24px] md:text-[56px] text-[#36A7C3] table" itemProp="headline">{pageTitle}</h1>
            </div>

            {/* パンくず */}
            <p className="text-[12px] mb-[20px] md:mb-[30px]">
              <div className="flex mb-2 md:mb-3">
                <img className="pr2" alt="" src={icon_post_SVG3}/>
                <a href={indexUrl} className="px-2 hover:text-gray-400">ホーム</a>
                <span className="pr-2 mx-0 my-auto inset-y-0 md:m-0">＞</span>
                <span className="pr-2">{pageTitle}</span>
              </div>
            </p>

            {/* １カラムコンテンツ */}
            {/* divだとbuild時置き換えが起こるのでpタグに */}
            <p> 
              <div className="max-w-xl w-full mx-auto md:pb-11 md:max-w-5xl mb-10">
                <h4>お問い合わせ</h4>
              </div> 
              <FormsParts/>
            </p>

          </div>
          <aside className="column2_filed px-5">
            
            {/*カテゴリ検索 */}
            <div>
              {searchList}
            </div>

            {/*ワード検索 */}
            {wordSearchContentPC}
            
            <div className="md:sticky">
              {/*関連記事 */}
              <h3 className="text-[#36A7C3] mt-13 md:mt-10 mb-5 font-semibold flex">関連記事</h3>
              <div className="flex-wrap justify-around mb-10 md:block flex">
                {relatedList}
              </div>
            </div>
            {/* PCプロフィール */}
            {profilePC}
          </aside>
        </div>
        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}

          {/* MBプロフィール */}
          {profileMB}
          
        </div>
        {/* 固定フッター */}
        <FixedFoot fixPattern="nomal" item="" />
      </Layout> 
    </>
  )
}

export const query = graphql`
  query contactquery {
    imagesettingData: allWpImagesettings {
      edges {
        node {
          title
          slug
          smartCustomFields {
            broker_name
            archive_text1
            archive_text2
            text_position
            font_color
            image
          }
          imagesetting {
            bannerSettingsGroup {
              fieldGroupName
              bannerArea1 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea2 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea3 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default FxContact
